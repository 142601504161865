<template>
    <div class="content_container signin">
        <h1>로그인</h1>
        <div class="con_signin">
            <div class="left">
                <div class="fill">
                    <label for="email">이메일</label>
                    <input
                        type="email"
                        id="email"
                        placeholder="이메일 주소"
                        v-model="email"
                        @keyup.enter="emailEnter"
                    />
                </div>

                <div class="fill password">
                    <label for="pass">비밀번호</label>
                    <input
                        type="password"
                        id="pass"
                        minlength="8"
                        required
                        placeholder="비밀번호"
                        v-model="password"
                        @keyup.enter="signin"
                    />
                    <div class="passShow" @click="togglePassShow($event)">
                        <i class="far fa-eye"></i>
                        <i class="fas fa-eye-slash on"></i>
                    </div>
                </div>
                <div class="checkbox">
                    <p>
                        <!-- <input
                            type="checkbox"
                            name="question"
                            id="autoLogin"
                            v-model="autoLogin"
                            @keyup.enter="signin"
                        />
                        <label for="autoLogin">자동로그인</label>
                        <span class="label_span">·</span> -->
                        <router-link to="/findpassword" class="find_pass">비밀번호 찾기</router-link>
                    </p>
                </div>
                <button id="btn_signin" type="button" @click="signin">로그인</button>
            </div>
            <div class="line"></div>
            <div class="right">
                <button
                    type="button"
                    class="google"
                    onclick="location.href='https://manager.meta-buysell.com/google/signin'"
                >
                    <img src="@/assets/images/login/ci_google.svg" alt="google" />
                    <p>Google로 로그인</p>
                </button>
                <button
                    type="button"
                    class="naver"
                    onclick="location.href='https://manager.meta-buysell.com/naver/signin'"
                >
                    <img src="@/assets/images/login/ci_naver.svg" alt="naver" />
                    <p>네이버로 로그인</p>
                </button>
                <button
                    type="button"
                    class="kakao"
                    onclick="location.href='https://manager.meta-buysell.com/kakao/signin'"
                >
                    <img src="@/assets/images/login/ci_kakao.svg" alt="kakao" />
                    <p>카카오로 로그인</p>
                </button>
            </div>
        </div>

        <div class="tap_sign">
            <p>아직 계정이 없으신가요? <router-link to="/signup">회원가입</router-link></p>
        </div>
    </div>
    <SubFooter />
</template>

<script>
import SubFooter from "@/components/SubFooter";
import { postData } from "@/utils/postData";

export default {
    components: {
        SubFooter,
    },
    created(){
        if(this.$route.query.msg){
            this.$store.commit('alert', this.$route.query.msg);
            this.$router.replace('/signin')
        }
    },
    mounted() {
        document.getElementById("btn_signin").focus();
    },
    data() {
        return {
            email: "",
            password: "",
            autoLogin: false,
        };
    },
    mixins: [postData],
    methods: {
        // 이메일 입력창에서 엔터시
        emailEnter() {
            if (this.password == "") {
                document.getElementById("pass").focus();
            } else {
                this.signin();
            }
        },
        // 비밀번호 보이기/가리기
        togglePassShow(event) {
            const target = event.currentTarget;
            if (target.previousElementSibling.type == "text") {
                target.previousElementSibling.type = "password";
            } else {
                target.previousElementSibling.type = "text";
            }
            target.children[0].classList.toggle("on");
            target.children[1].classList.toggle("on");
        },
        // 로그인
        signin() {
            if (this.email == "" || this.password == "") {
                this.$store.commit("alert", "아이디와 비밀번호를 입력해 주세요.");
            } else {
                this.postData(
                    "/signin",
                    {
                        email: this.email,
                        password: this.password,
                    },
                    (data) => {
                        this.$store.commit("user/signin", data.user);
                    },
                );
            }
        },
    },
};
</script>

<style scoped src="@/assets/css/layout_login.css"></style>
